<template>
  <v-container fluid>

    <v-row>
      <v-col
          class="mb-5"
          cols="auto"
          md="12"
          sm="12"
      >
        <v-card>
          <v-data-table
              :footer-props="{
                pageText: '{0}-{1} od {2}',
                itemsPerPageText: 'Klijenata po strani'
              }"
              :headers="headers"
              :items="positions"
              :search="searchString"
              class="elevation-1"
              multi-sort
          >

            <template v-slot:item.created_at="{ item }">
              <span>{{ new Date(item.created_at).toLocaleString() }}</span>
            </template>

            <template v-slot:top>
              <v-toolbar
                  flat
              >
                <v-toolbar-title>Klijenti</v-toolbar-title>

                <v-divider
                    class="mx-4"
                    inset
                    vertical
                ></v-divider>

                <v-text-field
                    v-model="searchString"
                    append-icon="mdi-magnify"
                    hide-details
                    label="Pretraga (naziv)"
                    single-line
                ></v-text-field>

                <v-spacer></v-spacer>

                <v-dialog
                    v-model="dialog"
                    max-width="700px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="mb-2"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                    >
                      Novi klijent
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ formTitle }}
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-form
                            ref="form"
                            v-model="formValid">
                          <v-row>
                            <v-col cols="12" hidden md="6" sm="12">
                              <v-text-field
                                  v-model="editedItem.id"
                                  label="Id"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.name"
                                  :rules="fieldRules.titleRules.concat(fieldRules.max200Chars)"
                                  clearable
                                  counter="200"
                                  label="Klijent"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.email"
                                  :rules="fieldRules.max200Chars"
                                  clearable
                                  counter="200"
                                  label="E-mail"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.address"
                                  :rules="fieldRules.titleRules.concat(fieldRules.max200Chars)"
                                  clearable
                                  counter="200"
                                  label="Adresa"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.city"
                                  :rules="fieldRules.titleRules.concat(fieldRules.max200Chars)"
                                  clearable
                                  counter="200"
                                  label="Grad"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.country"
                                  :rules="fieldRules.titleRules.concat(fieldRules.max200Chars)"
                                  clearable
                                  counter="200"
                                  label="Država"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.pib"
                                  :rules="fieldRules.titleRules.concat(fieldRules.max200Chars)"
                                  clearable
                                  counter="200"
                                  label="PIB"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                  v-model="editedItem.pdv"
                                  :rules="fieldRules.titleRules.concat(fieldRules.max200Chars)"
                                  clearable
                                  counter="200"
                                  label="PDV"
                                  required
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="red darken-1 font-weight-bold"
                          text
                          @click="close"
                      >
                        Zatvori
                      </v-btn>
                      <v-btn
                          :disabled="!enableSave"
                          color="green darken-1 font-weight-bold"
                          text
                          @click="validateForm"
                      >
                        Sačuvaj
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog
                    v-model="dialogDelete"
                    max-width="650"
                >
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ editedItem.name }}
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <p>
                          Da li ste sigurni da želite da izbrišete ovog klijenta?
                        </p>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="red darken-1 font-weight-bold"
                          text
                          @click="closeDelete"
                      >
                        Ne želim
                      </v-btn>
                      <v-btn
                          :disabled="!enableSave"
                          color="green darken-1 font-weight-bold"
                          text
                          @click="deleteItemConfirm"
                      >
                        Želim
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2"
                      small
                      v-bind="attrs"
                      @click="editItem(item)"
                      v-on="on"
                  >
                    mdi-pencil
                  </v-icon>

                </template>
                <span>Izmijeni</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2"
                      color="red darken-3"
                      small
                      v-bind="attrs"
                      @click="deleteItem(item)"
                      v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Izbriši</span>
              </v-tooltip>
            </template>

          </v-data-table>
        </v-card>
      </v-col>

      <v-snackbar
          v-model="snackbar.active"
          :color="snackbar.color"
          :timeout="2000"
          bottom
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="snackbar.active = false">
            Zatvori
          </v-btn>
        </template>
      </v-snackbar>

    </v-row>

  </v-container>
</template>

<script>
import {clientsHeaders} from '@/mixins/data-table-headers'
import {fieldRules} from "@/mixins/field-rules";
import {defaultClient} from "@/mixins/default-items";

export default {
  name: 'Partners',
  components: {},
  data: () => ({
    searchString: '',
    dialog: false,
    dialogDelete: false,
    headers: [],
    positions: [],
    formTitle: 'Novi unos',
    formValid: true,
    editedItem: {},
    fieldRules: null,
    enableSave: true,
    snackbar: {
      active: false,
      color: '',
      text: ''
    },
  }),
  created() {
    this.headers = clientsHeaders
    this.fieldRules = fieldRules
    this.editedItem = Object.assign({}, defaultClient)
    this.loadAllPositions();
  },
  methods: {
    async loadAllPositions() {
      await this.$store.dispatch('partners/getAllPartners').then((res) => {
        this.positions = res.data
      })
    },

    editItem(item) {
      this.editedItem = this.positions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.formTitle = item.name
      this.dialog = true
    },

    deleteItem(item) {
      this.editedItem = this.positions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    validateForm() {
      if (this.$refs.form.validate())
        this.save()
    },

    async save() {
      this.enableSave = false
      let path = 'savePartner'

      if (this.editedItem.id) {
        Object.assign(this.editedItem, {_method: "POST"})
        path = 'updatePartner'
      }

      await this.$store.dispatch('partners/' + path, this.editedItem).then((res) => {
        if (res.success) {
          this.loadAllPositions();
          this.showSnackbar('Uspješno sacuvano', 'green');
          this.close()
        } else {
          this.showSnackbar('Došlo je do greške, prijavite podršci', 'red');
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$store.dispatch('errorMessages/errorMapper', error.response.data.message).then(
              e => this.showSnackbar(e, 'red')
          )
        } else {
          this.showSnackbar('Došlo je do greške, prijavite podršci', 'red');
        }
      }).finally(() => {
        this.enableSave = true
        this.formTitle = "Novi unos"
      })
    },

    async deleteItemConfirm() {
      await this.$store.dispatch('partners/deletePartner', this.editedItem).then((res) => {
        if (res.success) {
          this.loadAllPositions();
          this.showSnackbar('Uspješno obrisano', 'green');
          this.closeDelete()
        } else {
          this.showSnackbar('Došlo je do greške, prijavite podršci', 'red');
        }
      }).catch(() => {
        this.showSnackbar('Došlo je do greške, prijavite podršci', 'red');
      }).finally(() => {
        this.closeDelete()
      })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultClient)
      })
      this.$refs.form.resetValidation()
      this.formTitle = "Novi unos"
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultClient)
      })
    },

    showSnackbar(text, color) {
      this.snackbar.active = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    }
  },
}
</script>
